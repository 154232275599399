import { useAbility } from '@casl/react';
import _ from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import {
  ApiQueryFilter,
  AppQueryFilter,
  FieldType,
  QueryFilter,
  isValue,
} from '@nl-lms/common/shared';
import { ActionStatus } from '@nl-lms/feature/automation/sdk';
import {
  LearningAssignment,
  LearningAssignmentInitialNotificationTemplateName,
  LearningAssignmentInstanceStatus,
  LearningAssignmentLearningContentType,
  getLearningAssignmentLearningContentType,
} from '@nl-lms/feature/learning-assignments/sdk';
import {
  LearningProgramInstanceNotificationAudienceName,
  LearningProgramInstanceStatus,
  LearningProgramScope,
} from '@nl-lms/feature/learning-programs/sdk';
import {
  NotificationsScope,
  SampleReceiversQuery,
} from '@nl-lms/feature/notifications/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Icon,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Sensitive,
  StatusTag,
  TableWithFullPagination,
  Typography,
  TypographyProps,
} from '@nl-lms/ui/components';
import {
  FilterBar,
  useListViewContext,
  useListViewTableColumns,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { formatConstantString } from '@nl-lms/ui/utils';
import {
  fetchAndMapLearners,
  mapAndLoadEnumsForSelect,
} from '@nl-lms/web/adminApp/_common/utils/fetchEntitiesForSelectMethods';
import { useShowAddLearningProgramAssignmentInstancesModal } from '@nl-lms/web/adminApp/modules/learning-program/LearningProgramAddAssignmentInstancesSideModal';

import { AbilityContext } from '../../../Can';
import { useTableAction } from '../../../_common/hooks';
import { learningProgramsApi } from '../../../_common/services/api';
import { getActionAbility } from '../../../_common/utils/getActionAbility';
import { routes } from '../../../lib/routes';
import { isAssignmentRule } from '../learning-program-rule-graph/utils';
import { useTriggerNotificationSendSideModal } from '../notifications/NotificationSendModal';
import { NotificationTemplateContext } from '../notifications/NotificationTemplateProvider';

const {
  useListLearningProgramInstancesQuery,
  useResolveLearningProgramInstancesMutation,
  useRemoveLearningProgramInstancesMutation,
  useGetLearningProgramInstanceQuery,
} = learningProgramsApi;

export const LearningProgramInstancesListTable = () => {
  const learningProgramId = useParams<{ id: string }>().id as string;
  const { sorting, query, onChangePagination, onChangeSorting, filters } =
    useListViewContext();
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    undefined
  );
  const { data, isLoading } = useListLearningProgramInstancesQuery(
    {
      query: { query: transformTsRestQuery(query) },
    },
    {
      pollingInterval,
    }
  );

  const ability = useAbility(AbilityContext);

  const [rows, pagination] = useListViewTableData(data);
  const [removePrograms] = useRemoveLearningProgramInstancesMutation();
  const [resolveInstances] = useResolveLearningProgramInstancesMutation();

  const { templatesByName } = useContext(NotificationTemplateContext);
  const showSendNotificationModal = useTriggerNotificationSendSideModal();
  const onSendNotificationModal = useCallback(
    async ({ list }: { list: string[] | AppQueryFilter }) => {
      let receiverFilters: string[] | ApiQueryFilter;
      if (!Array.isArray(list)) {
        const queryFilter = new QueryFilter({ filters: list });
        receiverFilters = queryFilter.apiQueryFilter;
      } else {
        receiverFilters = list;
      }
      const receivers: SampleReceiversQuery = {
        name: LearningProgramInstanceNotificationAudienceName,
        filters: receiverFilters,
      };

      await showSendNotificationModal(
        receivers,
        _.cloneDeep(
          templatesByName[LearningAssignmentInitialNotificationTemplateName]
            .content
        ),
        MentionOptions
      );
    },
    []
  );
  useEffect(() => {
    if (
      data &&
      data.rows.some(
        (row) =>
          row.status ===
          (LearningAssignmentInstanceStatus.CREATING as any as LearningProgramInstanceStatus)
      )
    ) {
      setPollingInterval(2000);
    } else {
      setPollingInterval(undefined);
    }
  }, [data]);
  const onClickSendNotification = useTableAction(onSendNotificationModal, {
    withRowCountProp: true,
    baseFilters: query.filters,
    showConfirmation: false,
    showNotification: false,
  });
  const onRemoveRow = useTableAction(removePrograms, {
    withRowCountProp: true,
    baseFilters: query.filters,
    confirmationMessage:
      'Are you sure that you want to remote this learning program instance?',
  });
  const onResolveBlockedInstances = useTableAction(resolveInstances, {
    withRowCountProp: true,
    confirmationMessage:
      'Are you sure that you want to unblock these learning program instances? This will create new learning assignment instances and notify the learners (if notifications are enabled).',
    alertMessage: (res) =>
      res?.error?.message ?? 'Blocked instances could not be resolved.',
  });
  const showAddAssignmentInstancesSideModal =
    useShowAddLearningProgramAssignmentInstancesModal();
  const showModal = useCallback(async (selection) => {
    await showAddAssignmentInstancesSideModal({
      selection,
      learningProgramId,
    });
    return true;
  }, []);
  const onClickAddInstances = useTableAction(showModal, {
    withRowCountProp: true,
    showConfirmation: false,
    showNotification: false,
  });

  const rowActions = [
    // {
    //   name: 'View As Graph',
    //   handler: onRemoveRow,
    //   Icon: Icon.GitIconRegular,
    // },
  ];

  const tableActions = [
    ...getActionAbility(
      NotificationsScope.send.action,
      NotificationsScope.send.resource,
      {
        name: 'Notify',
        handler: onClickSendNotification,
      }
    ),
    ...getActionAbility(
      LearningProgramScope.updateLearningProgramInstances.action,
      LearningProgramScope.updateLearningProgramInstances.resource,
      {
        name: 'Add Assignment Instances',
        handler: onClickAddInstances,
      }
    ),
    ...getActionAbility(
      LearningProgramScope.updateLearningProgramInstances.action,
      LearningProgramScope.updateLearningProgramInstances.resource,
      {
        name: 'Unblock',
        handler: onResolveBlockedInstances,
      }
    ),
    ...getActionAbility(
      LearningProgramScope.deleteLearningProgramInstances.action,
      LearningProgramScope.deleteLearningProgramInstances.resource,
      {
        name: 'Delete',
        handler: onRemoveRow,
      }
    ),
  ];

  const [columns, onChangeColumns] = useListViewTableColumns([
    {
      Header: 'Name',
      accessor: 'learner.id',
      Cell: ({ row }) => (
        <Sensitive>
          {ability.can('view', 'learner_page') ? (
            <Link
              to={routes.admin.learners.item.path.full(row.original.learnerId)}
            >
              {row.original.learner.firstName} {row.original.learner.lastName}
            </Link>
          ) : (
            <span>
              {row.original.learner.firstName} {row.original.learner.lastName}
            </span>
          )}
        </Sensitive>
      ),
    },
    {
      Header: 'Email',
      accessor: 'learner.email',
      Cell: ({ row }) => <Sensitive>{row.original.learner.email}</Sensitive>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortField: 'status',
      Cell: ({ row }) => {
        return (
          <StatusTag
            status={LearningProgramInstanceStatus[row.original.status]}
          />
        );
      },
    },
    {
      Header: 'Created On',
      accessor: 'createdAt',
      sortField: 'createdAt',
      Cell: ({ row }) => {
        return <PrettyDate value={row.original.createdAt} withTime />;
      },
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      sortField: 'updatedAt',
      Cell: ({ row }) => <PrettyDate value={row.original.updatedAt} withTime />,
    },
  ]);

  return (
    <TableWithFullPagination
      columns={columns}
      data={rows}
      isLoading={isLoading}
      sorting={sorting}
      tableActions={tableActions}
      rowActions={rowActions}
      pagination={pagination}
      onChangePagination={onChangePagination}
      onChangeSorting={onChangeSorting}
      onChangeColumns={onChangeColumns}
      ExpanderComponent={LearningProgramAssignmentInstancesGroupedTable}
    >
      <NoDataPlaceholder
        iconName={filters ? 'FileIcon' : 'FileTextIcon'}
        color={
          filters
            ? NoDataPlaceholderColor.warning
            : NoDataPlaceholderColor.default
        }
        title={
          filters
            ? 'There are no program instances that match your search'
            : 'There are no program instances created'
        }
        // @ts-ignore
        subtitle={filters ? 'You can try changing the active filters' : null}
      />
    </TableWithFullPagination>
  );
};

export const LearningProgramInstancesListTableFilters = () => {
  const { filters, onChangeFilters } = useListViewContext();
  const ability = useAbility(AbilityContext);
  return (
    <FilterBar
      id="learning-program-view"
      fields={[
        {
          name: 'learner_id',
          label: 'Learner',
          type: FieldType.select,
          loadOptions: fetchAndMapLearners,
          ValueComponent: ({ children }) => {
            return <Sensitive>{children}</Sensitive>;
          },
        },
        {
          name: 'status',
          label: 'Status',
          type: FieldType.select,
          loadOptions: mapAndLoadEnumsForSelect(LearningProgramInstanceStatus),
        },
        {
          name: 'created_at',
          label: 'Created At',
          type: FieldType.date,
        },
      ]}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

const LearningProgramInstancesListTableLabel = ({
  variant = 'h3',
}: {
  variant?: TypographyProps['variant'];
}) => {
  const { query } = useListViewContext();
  const { data, isLoading } = useListLearningProgramInstancesQuery({
    query: { query: transformTsRestQuery(query) },
  });

  if (isLoading) {
    return <Typography variant={variant}>Fetching Rows</Typography>;
  }

  const actualCount = data?.count || 0;
  return (
    <Typography variant={variant}>
      {actualCount || 0} Learning Program
      {actualCount > 1 ? 's' : ''}
    </Typography>
  );
};

LearningProgramInstancesListTable.Label =
  LearningProgramInstancesListTableLabel;

LearningProgramInstancesListTable.Filters =
  LearningProgramInstancesListTableFilters;

type RowType = 'assignment-instance' | 'action';
const LearningProgramAssignmentInstancesGroupedTable = ({
  row: learningProgramInstance,
}) => {
  const { data, isLoading } = useGetLearningProgramInstanceQuery({
    id: learningProgramInstance.id,
  });
  const rows = useMemo(() => {
    if (!data) return [];
    return [
      ...data.learningAssignmentInstances.map((row: any) => ({
        id: row.id,
        learningAssignmentName: row.learningAssignmentName,
        learningAssignmentId: row.learningAssignmentId,
        learningItemType: row.learningItemType,
        status: row.status,
        type: 'assignment-instance',
        scheduledOn: null,
        createdAt: row.createdAt,
        updatedAt: row.updatedAt,
        dueDate: row.dueDate,
      })),
      ...data.actions
        .filter(
          (action) =>
            (action.status === ActionStatus.scheduled ||
              action.status === ActionStatus.planned) &&
            isAssignmentRule(action)
        )
        .map((action) => {
          const learningAssignment = data.learningAssignments.find(
            (r) => r.id === action.entityId
          ) as LearningAssignment;
          if (!learningAssignment) {
            console.error('Learning assignment not found', action);
            return;
          }
          return {
            id: action.id,
            type: 'action',
            scheduledOn: action.scheduledOn,
            learningAssignmentName: data.learningAssignments.find(
              (r) => r.id === action.entityId
            )?.name,
            learningItemType:
              getLearningAssignmentLearningContentType(learningAssignment),
            learningAssignmentId: action.entityId,
            status: action.status,
            createdAt: action.createdAt,
            updatedAt: action.updatedAt,
            dueDate: null,
          };
        })
        .filter(isValue),
    ];
  }, [data]);

  const ability = useAbility(AbilityContext);
  return (
    <TableWithFullPagination
      data={rows}
      isLoading={isLoading}
      columns={[
        {
          Header: 'Assignment',
          accessor: 'learningAssignmentName',
          Cell: ({ row }) => {
            return ability.can('view', 'assignment_page') ? (
              <Link
                to={routes.admin.manage.assignments.item.path.full(
                  row.original.learningAssignmentId
                )}
              >
                {row.original.learningAssignmentName}
              </Link>
            ) : (
              <span>{row.original.learningAssignmentName}</span>
            );
          },
        },
        {
          Header: 'Type',
          accessor: 'learningItemType',
          Cell: ({ row }) =>
            formatConstantString(
              LearningAssignmentLearningContentType[
                row.original.learningItemType
              ]
            ),
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ row }) => {
            const statusEnum =
              row.original.type === 'action'
                ? ActionStatus
                : LearningAssignmentInstanceStatus;
            return <StatusTag status={statusEnum[row.original.status]} />;
          },
        },
        {
          Header: 'Assigned On',
          accessor: 'createdAt',
          Cell: ({ row }) => {
            if (row.original.type === 'action') {
              return '-';
            }
            return <PrettyDate value={row.original.createdAt} withTime />;
          },
        },
        {
          Header: 'Scheduled On',
          accessor: 'schedueledOn',
          Cell: ({ row }) => {
            if (row.original.type !== 'action') {
              return '-';
            }
            return <PrettyDate value={row.original.scheduledOn} withTime />;
          },
        },
      ]}
    />
  );
};

const MentionOptions = [
  {
    id: '{{learner.firstName}}',
    label: '{{learner.firstName}}',
  },
  { id: '{{learner.lastName}}', label: '{{learner.lastName}}' },
  { id: '{{learner.email}}', label: '{{learner.email}}' },
  {
    id: '{{learningProgram.name}}',
    label: '{{learningProgram.name}}',
  },
  {
    id: '{{portalUrl}}',
    label: '{{portalUrl}}',
  },
];
