import { NumberList } from 'aws-sdk/clients/iot';
import { AxiosInstance } from 'axios';

import { AppQuery } from '@nl-lms/common/shared';
import { LearnerGroup } from '@nl-lms/feature/organization/sdk';

export type FetchListResponse<T> = {
  count: number;
  rows: T[];
};

export type ListLiveSessionsResponse = FetchListResponse<LiveSession>;
export type ListLearningPathResponse = FetchListResponse<LearningPath>;

export type LiveSessionResourceListArg = {
  sessionId: string;
  query: AppQuery;
};

export type AuthAccessUser = {
  id: string;
  learnerId: string;
  roles: string[];
  scope: string[];
  email: string;
  lastName: string;
  firstName: string;
};

export type AuthAccess = {
  token: string;
  user: AuthAccessUser;
};

export type Location = {
  country: string;
  city: string;
  region: string;
};
export type Trainer = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  vendorId: string;
  phoneNo: string;
  country: string;
  region: string;
  city: string;
  deliverableCourses: string[];
  canDeliverAnyCourse: boolean;
  vendorName: string;
  courses: { name: string; id: string; courseId: string }[];
  location: Location;
  vendor: Vendor;
};

export type TrainerStatistics = {
  total: number;
  metric: {
    value: number;
    label: string | null;
    metadata: Record<string, unknown>;
  }[];
  name: string;
}[];

export type TrainerCourseStatistics = {
  value: number;
  courseId: string;
  courseName: string;
  trainerId: string;
  trainerName: string;
}[];

export type Vendor = {
  id: string;
  name: string;
  type: number;
  companyName: string;
  companyPhoneNo: string;
  companyEmail: string;
  country: string;
  city: string;
  region: string;
  location: string;
  address: string;
  touchpointEmail: string;
  touchpointName: string;
  touchpointPhoneNo: string;
};

export type Venue = {
  id: string;
  name: string;
  vendorId: string;
  country: string;
  city: string;
  region: string;
  building: string;
  touchpointEmail: string;
  touchpointName: string;
  floor: string;
  roomNo: string;
  seatsNo: number;
  address: string;
  features: string[];
  fullAddress?: string;
  Vendor: Partial<Vendor>;
};

export type CostType = {
  id: string;
  name: string;
  unit: string;
  resourceType: string;
};

type QuestionCondition = {
  questionId: string;
  answer: string[];
};

export type SurveyQuestionCondition = (
  | QuestionCondition
  | { combinator: 'and' | 'or' }
)[];

export type Survey = {
  id: string;
  name: string;
  description: string;
  tagIds: string[];
  tags?: Tag[];
  createdAt?: string;
  thumbnail: string;
  timeSpent?: number;
  canStartWithoutAssignment: boolean;
  questions?: {
    id: string;
    questionId: string;
    type: number;
    description: string;
    options: { title: string }[];
    order: number;
    condition: SurveyQuestionCondition;
    required: boolean;
    title: string;
  }[];
};

export type SurveyInstance = {
  id: string;
  surveyFormId: string;
  learnerId: string;
  trainingSessionId: string;
  survey: Survey;
  learner: Learner;
  trainingSession?: LiveSession;
  surveyFormInstanceQuestions?: SurveyInstanceQuestion[];
  startedOn: string;
  finishedOn: string;
  status: number;
  timeSpent: number;
};

export type SurveyInstanceQuestion = {
  id: string;
  surveyFormInstance: string;
  questionId: string;
  title: string;
  type: number;
  options: AssessmentQuestionOption[];
  answer: string[];
};

export type IndividualLearning = {
  id: string;
  name: string;
  type: number;
  deliveryType: number;
  adminOnly: boolean;
  vendorId: string;
  createdAt: string;
  updatedAt: string;
  details: string;
  tagIds: string[];
  vendor?: Vendor;
};

export type IndividualLearningInstance = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  timeSpent: number;
  details: string;
  type: number;
  deliveryType: number;
  learnerId: string;
  mentorLearnerId?: string;
  vendorId: string;
  createdAt: string;
  updatedAt: string;
  individualLearningId: string;
};

export type Checklist = {
  id: string;
  name: string;
  title: string;
  description: string;
  thumbnail: string;
  tagIds: string[];
  tags?: Tag[];
  competencyIds: string[];
  competencies?: Competency[];
  targetLearnerGroupIds: string[];
  targetLearnerGroups: Partial<LearnerGroup>[];
  checklistItems: (ChecklistItem & {
    mandatory: boolean;
    order: number;
    parentChecklistItemId: any;
  })[];
  createdAt?: string;
  updatedAt?: string;
  userId: string;
};

export type ChecklistItem = {
  id: string;
  title: string;
  description: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ChecklistChecklistItem = {
  id: string;
  checklistId: string;
  checklist: Checklist;
  checklistItemId: string;
  checklistItem: ChecklistItem;
  parentChecklistItemId: string;
  parentChecklistItem: ChecklistItem;
  mandatory: boolean;
  order: number;
  createdAt?: string;
  updatedAt?: string;
};

export type ChecklistInstance = {
  id: string;
  checklistId: string;
  status: number;
  learnerId: string;
  checkedItemsCount: number;
  learningAssignmentInstanceId: string;
  startedOn: string;
  finishedOn: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ChecklistChecklistItemInstance = {
  id: string;
  checklistId: string;
  checklistItemId: string;
  checklistInstanceId: string;
  checklistChecklistItemId: string;
  checked: boolean;
  title: string;
  description: string;
  mandatory: boolean;
  order: number;
  startedOn: string;
  finishedOn: string;
  createdAt?: string;
  updatedAt?: string;
};

export type LiveCourse = {
  id: string;
  language: string;
  description: string;
  prework: string;
  minPax: number;
  name: string;
  maxPax: number;
  feedbackFormIds: string[];
  registrationFormId: string;
  registrationType: string;
  schedule: string[];
  duration: any;
  requirements: string[];
  trainingSessionApprovalType: number;
  surveys: { id: string; surveyId: string; courseId: string; type: number }[];
  thumbnail: string;
  scheduleTemplate: any;
  trainingSessionApproverIds: string[];
  trainingSessionApprovers: Learner[];
  competencyIds: string[];
  competencies: Competency[];
  tags: Tag[];
  targetLearnerGroupIds: string[];
  tagIds: string[];
  type: number;
  rating: number | null;
  ratingCount: number | null;
  canRegisterToMultipleSessions: boolean;
  managerLearnerAttendedNotificationMessage: string | null;
  bestResult?: {
    courseId: string;
    trainingSessionId: string;
    status: number;
    startDate: string;
  };
  createdAt: string;
  vendorId: string;
  vendorName: string;
};

export type Learner = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  customerInternalId: string;
  managerId: string;
  managerName: string;
  learnerGroups?: { id: string; title: string }[];
  details: Record<string, string>;
  history?: {
    createdAt: string;
    diff: Record<
      | 'firstName'
      | 'lastName'
      | 'customerInternalId'
      | 'isManager'
      | 'details'
      | 'email',
      { old: any; new: any }
    >;
  }[];
  hierarchicManagers: Omit<Learner, 'hierarchicManagers'>[];
  deletedAt?: string;
  updatedAt?: string;
};

export type ContentFile = {
  id: string;
  name: string;
  path?: string;
  createdAt?: string;
  updatedAt?: string;
  isReady?: boolean;
  type?: number;
  metadata?: Record<string, any>;
};

export type ElearningCourse = {
  id: string;
  name: string;
  description: string;
  number: number;
  updatedAt: string;
  User: { firstName: string; lastName: string };
  createdAt: string;
  contentFileId: string;
  thumbnail: string;
  duration: number;
  competencies: Competency[];
  targetLearnerGroupIds: string[];
  vendorId: string;
  feedbackFormIds: string[];
  tagIds: string[];
  tags: Tag[];
  competencyIds: string[];
  elearningSessions: Omit<ElearningSession, 'course'>[];
  bestResult?: Omit<ElearningSession, 'course'>;
  ContentFile: ContentFile;
  deletedAt?: string;
  contentFileType?: number;
  contentFileVersion?: string;
};

export type LiveSessionTrainer = {
  id: string;
  resourceTrainer: Trainer;
  status: number;
  isMainTrainer: boolean;
  createdAt: string;
  updatedAt: string;
};

export type LiveSessionVenue = {
  resourceVenue: Venue;
  id: string;
  status: number;
};
export type LiveSession = Omit<LiveCourse, 'surveys'> & {
  id: string;
  courseId: string;
  name: string;
  startDate: string;
  endDate: string;
  status: number;
  type: number;
  venueStatus: string;
  trainerStatus: string;
  registered: number;
  maxPax: number;
  totalCosts: number;
  userFirstName: string;
  userLastName: string;
  country: string;
  region: string;
  attendanceFormUrl: string;
  surveys: ({ surveyId: string; type: number } & Survey)[];
  duration: any;
  course: LiveCourse;
  venue: LiveSessionVenue;
  feedbackForm: FeedbackForm;
  city: string;
  approvalType: number;
  approverIds: string[];
  approvers: Learner[];
  trainers: LiveSessionTrainer[];
  createdAt: string;
  tags: Tag[];
  tagIds: Tag[];
  targetLearnerGroupIds: Tag[];
  virtualLocation: string;
  isWaitinglist?: boolean;
  calendarEnabled: boolean;
  anonymizeFeedbackResponses: boolean;
  enableWaitingList: boolean;
};

export type ElearningSession = {
  id: string;
  learner: Partial<Learner>;
  course?: Partial<Omit<ElearningCourse, 'elearningSessions'>>;
  status: string | number;
  isOpened: boolean;
  createdAt: string;
  timeSpent: string;
  passedDueDate?: boolean;
  result: string;
  score: string;
  finishedOn: string;
  startedOn: string;
  ContentFile?: ContentFile;
};

export type LiveSessionCost = {
  id: string;
  resourceType: string;
  resourceName: string;
  displayUnitPrice: string;
  unit: string;
  displayConvertedUnitPrice: string;
};

export interface AutomatedAction {
  id: string;
  createdAt: string;
  updatedAt: string[];
  description: string;
  disabled: boolean;
  name: string;
  payload?: any;
  trainingSessionId: string;
}

export enum AssessmentValidationType {
  LastResult = 1,
  BestResult = 2,
}

export enum AssessmentQuestionFilterType {
  Question = 1,
  Filter = 2,
}

export interface AssessmentFormItem {
  id: string;
  assessmentQuestionId: string;
  assessmentFormId: string;
  questionType: number;
  type: string;
  position: number;
  questionCount: number;
  questionFilter: number;
  item: {
    total: number;
    count: number;
    title: string;
    tags: Tag[];
  };
}

export interface Assessment {
  id: string;
  name: string;
  description: string;
  tagIds: string[];
  competencyIds: string[];
  targetLearnerGroupIds: string[];
  thumbnail: string;
  minScore: number;
  maxScore?: number;
  duration: number;
  maxAttemptsCount: number;
  validationType: AssessmentValidationType;
  showResults: boolean;
  randomizeQuestionOrder: boolean;
  items: AssessmentFormItem[];
  assessmentFormItems: AssessmentFormItem[];
  userId: string;
  competencies?: Competency[];
}

export interface AssessmentInstance {
  id: string;
  assessmentFormId: string;
  learnerId: string;
  attemptsCount: number;
  maxAttemptsCount: number;
  maxAttemptsCountReached: boolean;
  isCompleted: boolean;
  score?: number;
  status?: number;
  Learner?: Partial<Learner>;
  assessmentForm?: Partial<Assessment>;
  assessmentFormInstanceAttemptQuestions?: AssessmentInstanceAttemptQuestion[];
  assessmentQuestions?: AssessmentQuestion[];
  answers?: any[];
}

export interface ScormFile {
  id: string;
  name: string;
}

export interface AssessmentInstanceAttempt {
  id: string;
  startedOn: string;
  finishedOn: string;
  timeSpent: number;
  answers: { questionId: string; answer: string[] }[];
  status: number;
  assessmentQuestions?: AssessmentQuestion[];
}

export type AssessmentQuestionOption = {
  title: string;
  correct: boolean;
};

export type AssessmentQuestion = {
  id: string;
  title: string;
  description?: string;
  tagIds: string[];
  isCorrect?: boolean;
  type: number;
  options: AssessmentQuestionOption[];
  order?: number;
};
export type AssessmentInstanceAttemptQuestion = {
  id: string;
  assessmentFormInstanceAttemptId: string;
  assessmentFormInstanceId: string;
  assessmentFormItemId: string;
  assessmentQuestionId: string;
  isCorrect: boolean;
  order?: number;
};

export type Tag = {
  id: string;
  title: string;
  scope: string;
  adminOnly: boolean;
};

export type Competency = Tag;

export type LearningPathItem = {
  order: number;
  id: string;
  name?: string;
};

export type LearningPathInstance = {
  id: string;
  name: string;
  status: number;
  progress: number;
};

export type LearningPath = {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  duration: number;
  tagIds: string[];
  targetLearnerGroupIds: string[];
  competencyIds: string[];
  tags: Tag[];

  competencies: Competency[];
  learningPathItems: LearningPathItem[];
  bestResult?: LearningPathInstance;
  learningPathInstances: LearningPathInstance[];
  learnerGroups: Pick<LearnerGroup, 'id' | 'title'>[];
};

export type LearningPathLearnerInstance = {
  id: string;
  createdAt: string;
  email: string;
  endedAt: string;
  firstName: string;
  items: LearningPathItem[];
  lastName: string;
  learner: Partial<Learner>;
  learnerId: string;
  learnerName: string;
  learningAssignmentInstanceId: string;
  learningPathId: string;
  status: number;
};

export type EmailTemplate = {
  id: string;
  name: string;
  description: string;
  html: Record<string, string>;
  sections: any[];
  translations: Record<string, any>;
  enabled: boolean;
};
export type ApiServicePathF0 = () => string;
export type ApiServicePathF1 = (p1: string) => string;
export type ApiServicePathF2 = (p1: string, p2: string) => string;

export interface ListResponse<Model> {
  count: number;
  rows: Model[];
}

export type ApiServicePaths<T> = T;

export type ApiRoutes = {
  trainer: {
    get: (id: string) => Promise<Trainer>;
    list: (query: AppQuery) => Promise<ListResponse<Trainer>>;
    bulkDelete: (ids: string[]) => Promise<boolean>;
    create: (entity: Partial<Trainer>) => Promise<boolean>;
    update: (entity: Partial<Trainer>) => Promise<boolean>;
    remove: (entity: Partial<Trainer> | string) => Promise<boolean>;
    listCourses: () => Promise<LiveCourse[]>;
    listVendors: () => Promise<Vendor[]>;
  };
  vendor: {
    get: (id: string) => Promise<Vendor>;
    list: (query: AppQuery) => Promise<ListResponse<Vendor>>;
    bulkDelete: (ids: string[]) => Promise<boolean>;
    create: (entity: Partial<Vendor>) => Promise<boolean>;
    update: (entity: Partial<Vendor>) => Promise<boolean>;
    listForSelect: (input?: string) => Promise<Vendor[]>;
  };
  venue: {
    get: (id: string) => Promise<Venue>;
    list: (query: AppQuery) => Promise<ListResponse<Venue>>;
    bulkDelete: (ids: string[]) => Promise<boolean>;
    create: (entity: Partial<Venue>) => Promise<boolean>;
    update: (entity: Partial<Venue>) => Promise<boolean>;
  };
  activityLog: {};
  assessment: {};
  assessmentQuestion: {};
  auth: {};
  common: {};
  costType: {};
  course: {};
  elearningCourse: {};
  elearningSession: {};
  email: {};
  feedbackForm: {};
  learner: {};
  report: {};
  room: {};
  contentFile: {};
  session: {};
  tag: {};
  survey: {};
  competency: {};
  user: {};
  learningPath: {};
  learnerApp: {};
  learningAssignment: {};
  individualLearning: {};
  checklist: {};
};

export type IApiService<ServiceName extends keyof ApiRoutes> = {
  api: AxiosInstance;
  paths: Record<keyof ApiRoutes[ServiceName], (param?: string) => string>;
};

export type IApiMethods<ServiceName extends keyof ApiRoutes> = {
  [K in keyof ApiRoutes[ServiceName]]: ApiRoutes[ServiceName][K];
};

export enum Models {
  LiveSession = 'LiveSession',
  Learner = 'Learner',
}

export interface AssignmentArgs {
  id: string;
  name?: string;
  learnerGroupIds: string[];
  dueDate?: Date;
  startDate?: Date;
  mandatory: boolean;
  autoAssign: boolean;
}

export type LearningAssignmentModelType =
  | 'elearning'
  | 'assessment'
  | 'learningPath'
  | 'survey'
  | 'liveCourse'
  | 'checklist';

export type LearningApproval = {
  approver_id: string;
  created_at: Date;
  entity_id: string;
  id: string;
  learner_id: string;
  payload: any;
  status: number;
  type: number;
  updated_at: Date;
};

export type MandatoryAssignmentsReport = {
  finishedMandatoryAssignments: number;
  allMandatoryAssignments: number;
};

export type MostAccessedSessionReport = {
  courseName: string;
  courseId: string;
};

export type MostActiveUserReport = {
  learnerActivityCount: string;
  learnerId: string;
  learnerName: string;
};

export type SessionActivityProgressReport = {
  status: number;
  statusPercentage: string;
  statusTotal: string;
  total: string;
};

export type TrainedLearnersReport = {
  percentage: string;
  totalAll: string;
  totalLearned: string;
};

export type SessionFillRateReport = {
  percentage: string;
  registered: string;
  total: string;
};

export type CancelledSessionsReport = {
  statusPercentage: string;
  statusTotal: string;
  total: string;
};

export type CostPerLearnerReport = {
  avgParticipantPrice: string;
  participantPrice: string;
  totalParticipants: string;
  totalPrice: string;
};

export type ActivityLogItem = {
  message: string;
  timestamp: string;
  total: number;
  trainingSessionId: string;
  trainingSessionName: string;
};
export type LearnerLearningActivity = {
  id: string;
  name: string;
  learner_id: string;
  learning_type: number;
  status: string;
  due_date: Date;
  created_at: Date;
  start_date: Date;
  end_date: Date;
  updated_at: Date;
  mandatory: boolean;
  learning_assignment_id: string;
  learning_assignment_instance_id: string;
  score: number;
  parent_id: string;
  instance_id: string;
};

export type EmailItem = {
  id: string;
  to: string;
  from: string;
  html: string;
  subject: string;
  replyTo: string;
  cc: string;
  userId: string;
  isSent: boolean;
};

export type LearnerLearningAssignmentStatus = {
  value: number;
  status: string;
};

export type LearnerTimeSpentLearning = {
  timeSpentLearning: number;
};

export type LearnerLearningByDay = {
  value: number;
  learningDate: string;
};

export type LearnerLearningByType = {
  value: number;
  type: string;
};

export interface LearnerLearning {
  id: string;
  dueDate?: string;
  startDate?: string;
  mandatory: boolean;
  name: string;
  thumbnail: string;
  description: string;
  duration: number;
  competencyIds: string[];
  learnerId: string;
  learningAssignmentId: string;
  trainingSessionId?: string;
  type: number;
  createdAt: Date;
  status: number;
  parentId: string;
  competencies: Competency[];
  playerUrl?: string;
}

export interface FeedbackForm {
  name: string;
  id: string;
}

export interface LearnerElearningCourseListItem {
  competencies: Competency[];
  competency_ids: string[];
  content_file_id: string;
  content_file_version: string;
  content_file_type: string;
  content_type: string;
  course_player_url: string;
  created_at: string;
  customer_internal_id: string;
  deleted_at: string;
  description: string;
  elearning_course_history_id: string;
  id: string;
  name: string;
  tag_ids: string[];
  target_learner_group_ids: string[];
  thumbnail: string;
  updated_at: string;
  bestResult?: ElearningSession;
}

type LiveSessionWithLearner = Partial<LiveSession> & {
  trainingSessionLearner: LiveSessionLearner;
  location: string;
  remainingSeats: number;
  enableWaitingList: boolean;
};

export type LearnerLiveCourse = {
  course: LiveCourse & { isRegistered: boolean };
  trainingSessions: LiveSessionWithLearner[];
  activity: Partial<LiveSessionLearner>[];
};

type ElearningSessionWithAssignment = ElearningSession & {
  learningAssignmentInstanceId: string;
};

export type LearnerElearningCourse = {
  elearningCourse: ElearningCourse;
  elearningSessions: ElearningSessionWithAssignment[];
  selectedSession: ElearningSession;
};

type LearningPathItemWithInstance = LearningPathItem & {
  sequential: boolean;
  learningPathItemInstance: any;
};

export type LearnerLearningPath = {
  learningPath: LearningPath;
  learningPathInstance: LearningPathInstance;
  learningPathItems: LearningPathItemWithInstance[];
};

export type LearnerChecklist = {
  checklist: Checklist;
  checklistInstances: ChecklistInstance[];
};

export type LearnerChecklistInstance = ChecklistInstance &
  LearnerLearning & {
    timeSpent: number;
    checklist: Checklist;
    checklistItems:
      | (ChecklistItem & ChecklistChecklistItem)[]
      | ChecklistChecklistItemInstance[];
  };

export type LearnerLearningPathListItem = Omit<
  LearningPath,
  'tags' | 'learner_groups'
> & {
  bestResult?: LearningPathInstance;
  duration: number;
};

export type LearnerRegistrationResult =
  | 'REGISTERED'
  | 'ADDED_TO_WAITING_LIST'
  | 'APPROVAL_SENT';

type BaseSearchResult = {
  id: string;
  name: string;
  count: number;
  details: {};
};

type LiveSessionSearchResult = BaseSearchResult & {
  type: 'live session';
  details: {
    status: number;
    startDate?: string;
  };
};
type LearnerSearchResult = BaseSearchResult & {
  type: 'learner';
  details: { email: string };
};
type LearningAssignmentSearchResult = BaseSearchResult & {
  type: 'learning assignment';
};
type LiveCourseSearchResult = BaseSearchResult & { type: 'live course' };
type ElearningCourseSearchResult = BaseSearchResult & {
  type: 'elearning course';
};
type LearningPathSearchResult = BaseSearchResult & { type: 'learning path' };
type AssessmentSearchResult = BaseSearchResult & { type: 'assessment' };
type SurveySearchResult = BaseSearchResult & { type: 'survey' };
type TrainerSearchResult = BaseSearchResult & { type: 'trainer' };
type VenueSearchResult = BaseSearchResult & { type: 'venue' };
type VendorSearchResult = BaseSearchResult & { type: 'vendor' };
type ChecklistSearchResult = BaseSearchResult & { type: 'checklist' };
type TagSearchResult = BaseSearchResult & { type: 'tag' };
export type AdminSearchResult =
  | LiveSessionSearchResult
  | LiveCourseSearchResult
  | LearnerSearchResult
  | ElearningCourseSearchResult
  | LearningAssignmentSearchResult
  | LearningPathSearchResult
  | AssessmentSearchResult
  | SurveySearchResult
  | TrainerSearchResult
  | VendorSearchResult
  | VenueSearchResult
  | ChecklistSearchResult
  | TagSearchResult;

export type LearnerLiveCourseSearchResult = BaseSearchResult & {
  type: 'live course';
};
export type LearnerElearningCourseSearchResult = BaseSearchResult & {
  type: 'elearning course';
};
export type LearnerLearningPathSearchResult = BaseSearchResult & {
  type: 'learning path';
};

export type LearnerPortalSearchResult =
  | LearnerLiveCourseSearchResult
  | LearnerElearningCourseSearchResult
  | LearnerLearningPathSearchResult;

export type LiveSessionLearnerType = 'approval' | 'accepted' | 'waiting';
export type LiveSessionLearner = {
  id: string;
  learnerId: string;
  trainingSessionId: string;
  status: number;
  type: LiveSessionLearnerType;
  createdAt: string;
  updatedAt: string;
  learner: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  trainingSession: {
    id: string;
    name: string;
    startDate: string;
    status: number;
  };
  registrationSurveyFormResponse?: {
    id: string;
    learnerId: string;
    surveyFormInstanceId: string;
    trainingSessionId: string;
    trainingSessionLearnerId: string;
    surveyFormInstanceResponses: Record<
      string,
      {
        answer: string[] | string;
        title: string;
        questionId: string;
        options: { title: string }[];
      }
    >;
  };
};

export type LiveSessionStatistics = {
  nps: number[];
  fillRate: number;
  totalCost: number;
  costPerLearner: number;
};
