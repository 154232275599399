import { z } from 'zod';

export const UserSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  avatar: z.string().nullable(),
  learnerId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type User = z.infer<typeof UserSchema>;

export const UserRoleSchema = z.object({
  id: z.string(),
  userId: z.string(),
  role: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type UserRole = z.infer<typeof UserRoleSchema>;

export const UserLoginSchema = z.object({
  id: z.string(),
  userId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type UserLogin = z.infer<typeof UserLoginSchema>;

export const RegistrationSchema = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  confirmed: z.boolean(),
  details: z.record(z.string(), z.unknown()),
  referral: z.record(z.string(), z.unknown()),
  userId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});
export type Registration = z.infer<typeof RegistrationSchema>;
