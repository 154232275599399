import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import {
  adminApi,
  automationApi,
  certificationApi,
  contentLibraryItemsApi,
  dashboardApi,
  elearningApi,
  learnerApi,
  learningAssignmentsApi,
  learningProgramsApi,
  liveLearningApi,
  notificationsApi,
  organizationApi,
  reportsApi,
  subscriptionsApi,
} from './_common/services/api';
import * as sentry from './lib/sentry';
import { rootReducer } from './rootReducer';

const middleware = [
  adminApi.middleware,
  learnerApi.middleware,
  elearningApi.middleware,
  dashboardApi.middleware,
  reportsApi.middleware,
  automationApi.middleware,
  certificationApi.middleware,
  organizationApi.middleware,
  liveLearningApi.middleware,
  subscriptionsApi.middleware,
  learningAssignmentsApi.middleware,
  learningProgramsApi.middleware,
  notificationsApi.middleware,
  contentLibraryItemsApi.middleware,
];

const sentryEnhancer = sentry.getReduxEnhancer();

export const store = configureStore({
  reducer: rootReducer,
  //@ts-ignore
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(middleware);
  },
  // @ts-ignore
  devTools: process.env.NODE_ENV !== 'production',
  // enhancers: (getDefaultEnhancers) => {
  //   console.log(getDefaultEnhancers);
  //   // console.log(getDefaultEnhancers[0]);
  //   getDefaultEnhancers().concat(sentryEnhancer);
  //   // console.log(defaultEnhancers);
  //   // return [...defaultEnhancers, sentryEnhancer];
  // },
});

let initialized = false;
setupListeners(store.dispatch, (dispatch, { onFocus, onFocusLost }) => {
  // see https://redux-toolkit.js.org/rtk-query/api/setupListeners for rest of logic if needed
  const handleFocus = () => dispatch(onFocus());
  const handleFocusLost = () => dispatch(onFocusLost());
  const handleVisibilityChange = () => {
    if (window.document.visibilityState === 'visible') {
      handleFocus();
    } else {
      handleFocusLost();
    }
  };

  if (!initialized) {
    // Handle focus events
    window.addEventListener('visibilitychange', handleVisibilityChange, false);

    initialized = true;
  }

  const unsubscribe = () => {
    window.removeEventListener('focus', handleFocus);
    window.removeEventListener('visibilitychange', handleVisibilityChange);

    initialized = false;
  };

  return unsubscribe;
});
