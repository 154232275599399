import {
  isArray,
  map,
  isObject,
  transform,
  snakeCase,
  isDate,
  isRegExp,
  reduce,
  keys,
  mapKeys,
  clone,
  camelCase,
  upperFirst,
} from 'lodash';

const snakeCaseTransform = (obj) => {
  let result = obj;
  if (isArray(obj)) {
    result = map(obj, (v) => snakeCaseTransform(v));
  } else if (isObject(obj)) {
    result = transform(
      // @ts-ignore
      obj,
      (res, val, key) => {
        // @ts-ignore
        res[snakeCase(key)] = snakeCaseTransform(val);
      },
      {}
    );
  }
  return result;
};

export const toCamelCase = (str) =>
  str.replace(/[_.-](\w|$)/g, (i, x) => x.toUpperCase());

export const toPascalCase = (str) => upperFirst(toCamelCase(str));

const camelCaseTransform = (data) => {
  if (!data || typeof data !== 'object') return data;
  if (isDate(data) || isRegExp(data)) return data;
  if (isArray(data)) return map(data, camelCaseTransform);
  return reduce(
    keys(data),
    (acc, key) => {
      const camel = toCamelCase(key);
      acc[camel] = camelCaseTransform(data[key]);
      return acc;
    },
    {}
  );
};

export const camelize = (obj) => camelCaseTransform(obj);

export const snakelize = (obj, deep = true) => {
  if (!deep) return mapKeys(obj, (val, key) => snakeCase(key));
  return snakeCaseTransform(obj);
};

export const fillExtend = function (arr, elem, from = 0, to = arr.length) {
  if (to < 0) return [];

  const filledArr = clone(arr);
  for (let index = from; index <= to; index += 1) {
    filledArr[index] = elem;
  }

  return filledArr;
};

export function isNumeric(str: string): boolean {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
