import * as z from 'zod';

export const RegisterSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  password: z.string(),
  referral: z.record(z.string(), z.unknown()),
  details: z.record(z.string(), z.unknown()),
});
