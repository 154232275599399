import { useContext } from 'react';

import { TrackingContext } from './TrackingProvider';
import { Tracker } from './tracker';

export const useTracker = (): Tracker => {
  const { tracker } = useContext(TrackingContext);

  return tracker;
};
