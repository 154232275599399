import { combineReducers } from '@reduxjs/toolkit';

import {
  adminApi,
  automationApi,
  certificationApi,
  contentLibraryItemsApi,
  dashboardApi,
  elearningApi,
  learnerApi,
  learningAssignmentsApi,
  learningProgramsApi,
  liveLearningApi,
  notificationsApi,
  organizationApi,
  reportsApi,
  subscriptionsApi,
} from './_common/services/api';
import learnerAssessmentPlayer from './learnerApp/modules/LearnerAssessmentPlayer/learnerAssessmentPlayerSlice';

export const rootReducer = combineReducers({
  [adminApi.reducerPath]: adminApi.reducer,
  [learnerApi.reducerPath]: learnerApi.reducer,
  [automationApi.reducerPath]: automationApi.reducer,
  [certificationApi.reducerPath]: certificationApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [elearningApi.reducerPath]: elearningApi.reducer,
  [learningAssignmentsApi.reducerPath]: learningAssignmentsApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [liveLearningApi.reducerPath]: liveLearningApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [learningProgramsApi.reducerPath]: learningProgramsApi.reducer,
  [contentLibraryItemsApi.reducerPath]: contentLibraryItemsApi.reducer,
  learnerAssessmentPlayer,
});

export type RootState = ReturnType<typeof rootReducer>;
