import { z } from 'zod';

import {
  LiveCourseScheduleSchema,
  LiveCourseSchema,
  LiveCourseType,
} from '../live-course/live-course.types';
import { SurveyFormLearningUnitSchema } from '../survey-forms/survey-form.types';

export enum LiveSessionStatus {
  DRAFT = 1,
  PROCESSING = 2,
  READY = 3,
  COMPLETED = 4,
  CANCELED = 5,
}

export enum LiveSessionLearnerStatus {
  NOT_INVITED = 1,
  INVITED = 2,
  REGISTERED = 3,
  DECLINED = 4,
  CANCELLED = 5,
  ATTENDED = 6,
  ABSENT = 7,
  GO_SHOW = 8,
  APPROVAL = 9,
  APPROVAL_DECLINED = 10,
  WAITING_LIST = 11,
}

export enum LiveSessionApprovalType {
  NONE = 1,
  TRAINING_SESSION_DIRECT_MANAGER,
  TRAINING_SESSION_HIERARCHIC_MANAGER,
}

export enum VirtualLocationType {
  'GMEET' = 1,
  'MS_TEAMS',
}

export const LiveSessionSchema = z.object({
  id: z.string(),
  name: z.string(),
  language: z.string(),
  minPax: z.number(),
  maxPax: z.number(),
  startDate: z.date().nullable(),
  endDate: z.date().nullable(),
  status: z.nativeEnum(LiveSessionStatus),
  courseId: z.string(),
  userId: z.string(),
  country: z.string().nullable(),
  region: z.string().nullable(),
  city: z.string().nullable(),
  description: z.string().nullable(),
  prework: z.string().nullable(),
  requirements: z.array(z.string()),
  approverIds: z.array(z.string()),
  approvalType: z.nativeEnum(LiveSessionApprovalType),
  enableWaitingList: z.boolean(),
  anonymizeFeedbackResponses: z.boolean(),
  scheduleTemplate: LiveCourseScheduleSchema,
  thumbnail: z.string(),
  type: z.nativeEnum(LiveCourseType),
  virtualLocation: z.string().nullable(),
  calendarEnabled: z.boolean(),
  virtualLocationType: z.nativeEnum(VirtualLocationType),
  vendorId: z.string().nullable(),
  tagIds: z.array(z.string()),
  targetLearnerGroupIds: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const LiveSessionExtendedSchema = LiveSessionSchema.extend({
  location: z.string(),
  locationPreview: z.string(),
  hasApproval: z.boolean(),
  duration: z.object({
    days: z.number(),
    totalMinutes: z.number(),
    contentMinutes: z.number(),
    breakMinutes: z.number(),
  }),
  registeredPax: z.number().nullish(),
  remainingSeats: z.number().nullish(),
  registrationForm: SurveyFormLearningUnitSchema.nullish(),
});

export type LiveSessionExtended = z.infer<typeof LiveSessionExtendedSchema>;

export const LiveSessionLearnerSchema = z.object({
  id: z.string(),
  learnerId: z.string(),
  trainingSessionId: z.string(),
  status: z.nativeEnum(LiveSessionLearnerStatus),
  learnerHistoryId: z.string().nullable(),
  approverId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const LiveSessionLearnerLearningAssignmentInstanceSchema = z.object({
  id: z.string(),
  learnerId: z.string(),
  courseId: z.string(),
  trainingSessionLearnerId: z.string().nullable(),
  learningAssignmentInstanceId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const LiveSessionRoomSchema = z.object({
  id: z.string(),
  trainingSessionId: z.string(),
  resourceRoomId: z.string(),
  status: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const LiveCourseWithUpcomingSessionsAndActivitySchema =
  LiveCourseSchema.extend({
    upcomingSessions: z.array(LiveSessionSchema),
    activity: z.array(LiveSessionLearnerSchema),
  });

export type LiveSessionLearner = z.infer<typeof LiveSessionLearnerSchema>;
export type LiveSessionLearnerLearningAssignmentInstance = z.infer<
  typeof LiveSessionLearnerLearningAssignmentInstanceSchema
>;
export type LiveSessionRoom = z.infer<typeof LiveSessionRoomSchema>;
export type LiveSession = z.infer<typeof LiveSessionSchema>;
