import store from 'store';

import { getOrganizationClients } from '@nl-lms/feature/organization/sdk';

import { apiBaseUrl } from './api';

const { raw: learnerGroupsApiClient, rtk: learnerGroupsRtkClient } =
  getOrganizationClients({
    baseUrl: `${apiBaseUrl('v2')}`,
    tokenGetter: () => store.get('accessToken'),
  });

export { learnerGroupsApiClient };

export const organizationApi = learnerGroupsRtkClient;
organizationApi.enhanceEndpoints<'Learner' | 'LearnerGroup'>({
  endpoints: {
    getLearner: { providesTags: [{ type: 'Learner', id: 'LIST' }] },
    listLearners: { providesTags: [{ type: 'Learner', id: 'LIST' }] },
    listLearnerGroups: { providesTags: [{ type: 'LearnerGroup', id: 'LIST' }] },
    exportLearnerGroups: {
      providesTags: [{ type: 'LearnerGroup', id: 'LIST' }],
    },
    updateLearnerGroup: {
      invalidatesTags: [{ type: 'LearnerGroup', id: 'LIST' }],
    },
    createLearnerGroup: {
      invalidatesTags: [{ type: 'LearnerGroup', id: 'LIST' }],
    },
    deleteLearnerGroups: {
      invalidatesTags: [{ type: 'LearnerGroup', id: 'LIST' }],
    },
    importLearnerGroups: {
      invalidatesTags: [{ type: 'LearnerGroup', id: 'LIST' }],
    },
  },
});
