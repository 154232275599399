import { z } from 'zod';

export enum SurveyFormType {
  Feedback = 1,
  Registration = 2,
}

export enum SurveyFormInstanceStatus {
  NotStarted = 1,
  InProgress = 2,
  Completed = 3,
  Canceled = 4,
}

export const SurveyFormLearningUnitSchema = z.object({
  id: z.string(),
  surveyFormId: z.string(),
  type: z.nativeEnum(SurveyFormType),
  courseId: z.string().nullable(),
  trainingSessionId: z.string().nullable(),
  elearningCourseId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type SurveyFormLearningUnit = z.infer<
  typeof SurveyFormLearningUnitSchema
>;

export const SurveyFormSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  thumbnail: z.string(),
  tagIds: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type SurveyForm = z.infer<typeof SurveyFormSchema>;

export const SurveyFormInstanceSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(SurveyFormInstanceStatus),
  surveyFormId: z.string(),
  learnerId: z.string().nullable(),
  courseId: z.string().nullable(),
  trainingSessionId: z.string().nullable(),
  elearningCourseId: z.string().nullable(),
  learnerHistoryId: z.string().nullable(),
  learningAssignmentInstanceId: z.string().nullable(),
  startedOn: z.date().nullable(),
  finishedOn: z.date().nullable(),
  timeSpent: z.number().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type SurveyFormInstance = z.infer<typeof SurveyFormInstanceSchema>;

export const SurveyFormInstanceQuestionSchema = z.object({
  id: z.string(),
  surveyFormInstanceId: z.string(),
  questionId: z.string(),
  title: z.string(),
  type: z.number(),
  options: z.array(z.unknown()),
  answer: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type SurveyFormInstanceQuestion = z.infer<
  typeof SurveyFormInstanceQuestionSchema
>;

export const SurveyFormQuestionSchema = z.object({
  id: z.string(),
  surveyFormId: z.string(),
  questionId: z.string(),
  order: z.number(),
  condition: z.record(z.string(), z.unknown()).nullable(),
  required: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type SurveyFormQuestion = z.infer<typeof SurveyFormQuestionSchema>;
